import { HTMLAttributes } from 'react';
import { SVGElement } from '@amf/shared/types/utils';
import classNames from 'classnames';

type Props = HTMLAttributes<HTMLButtonElement> & {
  icon?: SVGElement;
  variant: 'green' | 'white' | 'red' | 'gray';
  size?: 'md' | 'sm';
};

function Button({ size, variant, className, children, icon, ...props }: Props) {
  const iconProps = { icon };
  return (
    <button
      className={classNames(
        'font-primary normal-case font-medium',
        'hover:shadow active:scale-[.98] focus:ring-2 ring-offset-2',
        'inline-flex items-center text-center',
        'transition-all outline-0',
        {
          'bg-wmf-green text-white hover:bg-wmf-green/90 focus:ring-wmf-green': variant === 'green',
          'bg-wmf-red text-white hover:bg-wmf-red/90 focus:ring-wmf-red': variant === 'red',
          'bg-white text-neutral-950 hover:bg-neutral-100 focus:ring-white ring-offset-black':
            variant === 'white',
          'bg-neutral-200 text-neutral-950 hover:bg-neutral-200 focus:ring-neutral-200 ring-offset-white':
            variant === 'gray',
          'gap-2 text-lg sm:text-xl h-14 px-8 rounded-lg': size === undefined || size === 'md',
          'gap-1.5 text-lg h-10 px-6 rounded-md': size === 'sm',
        },
        className,
      )}
      {...props}
    >
      {iconProps.icon && <iconProps.icon className='w-6 h-6 min-w-[1.5rem] hidden sm:block' />}
      <span className='flex-grow'>{children}</span>
    </button>
  );
}

export default Button;
